import Vue from "vue";
import Vuex from "vuex";
import IAdminAuctionModuleState from "./adminAuction.module";
import IAdminUserModuleState from "./adminUser.module";
import IAdminWatchdogState from "./adminWatchdog.module";
import IAdminPriceDemandState from "./adminPriceDemand.module";
import IAdminSellOfferState from "./adminSellOffer.module";
import IAuthModuleState from "./auth.module";
import IFormModuleState from "./form.module";

Vue.use(Vuex);

export interface IRootState {
  adminAuctionModule: IAdminAuctionModuleState;
  adminUserModule: IAdminUserModuleState;
  AdminWatchdogModule: IAdminWatchdogState;
  AdminPriceDemandModule: IAdminPriceDemandState;
  AdminSellOfferModule: IAdminSellOfferState;
  authModule: IAuthModuleState;
  formModule: IFormModuleState;
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<IRootState>({});

import Vue from "vue";
import store from "../modules";
import Home from "./page/home.vue";

const components = [
  {
    component: Home,
    element: "home",
  },
];

export default {
  loadComponents() {
    components.forEach(({ component, element }) => {
      if (!document.querySelector(element)) {
        return;
      }

      const object: any = {};
      object[element] = component;

      new Vue({
        components: object,
        store,
        // render: (createElement) => createElement(component),
      }).$mount(element);
    });
  },
};















































import { Component, PropSync, Vue } from "vue-property-decorator";

@Component
export default class CardOfferSmall extends Vue {
  @PropSync("offer", {
    default: null,
  })
  privateOffer!: {};

  numberFormat(val: number) {
    return new Intl.NumberFormat("cs-CZ").format(val);
  }
}

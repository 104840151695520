








































import { Component, PropSync, Vue } from "vue-property-decorator";
import CardOfferSmall from "../cards/cardOfferSmall.vue";
import Switch3 from "../partials/switch3.vue";
// @ts-ignore
import { latLngBounds, latLng, icon } from "leaflet";
import "proj4leaflet";
// @ts-ignore
import proj4 from "proj4";
declare let L: any;

@Component({ components: { CardOfferSmall, Switch3 } })
export default class Map extends Vue {
  @PropSync("items")
  privateItems!: any;

  markers: any[] = [];
  selected: string = "all";
  mapFilterValue = 0;

  mapOptions = {
    zoom: 13,
    center: [0, 0],
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    marker: latLng(47.41322, -1.219482),
  };

  mounted() {
    this.privateItems.forEach((item: any) => {
      this.markers.push({
        type: item.type,
        latLng: this.getLatLng(item.holdings[0].geoJson),
        url: `/pozemky/${item.type === "DirectSale" ? "prodej" : "aukce"}/${
          item.id
        }/${item.title}`,
        name: item.name,
        item: item,
        icon: icon({
          iconSize: [50, 50],
          iconAnchor: [25, 50],
          iconUrl:
            item.type === "DirectSale"
              ? require("../../assets/images/icons/map-marker-primary.svg")
              : require("../../assets/images/icons/map-marker-secondary.svg"),
        }),
      });
    });
  }

  get bounds() {
return latLngBounds(	
      this.markers.map((marker) => [marker.latLng.lat, marker.latLng.lng])
    );
  }

  private getLatLng(geo: string) {
    var proj5514 =
      "+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=570.8,85.7,462.8,4.998,1.587,5.261,3.56 +units=m +no_defs";

    proj4.defs("urn:ogc:def:crs:EPSG::5514", proj5514);

    const geoJson = JSON.parse(geo);
    const value = L.Proj.geoJson({
      type: "Feature",
      geometry: geoJson.geometry,
      crs: {
        type: "name",
        properties: {
          name: "urn:ogc:def:crs:EPSG::5514",
        },
      },
    });

    return value.getBounds().getCenter();
  }

  get filterIsVisible() {
    return true;
    // return (
    //   this.model.Auctions &&
    //   this.model.Auctions.auctions &&
    //   this.model.Auctions.auctions.length > 0 &&
    //   this.model.DirectSales &&
    //   this.model.DirectSales.auctions &&
    //   this.model.DirectSales.auctions.length > 0
    // );
  }

  get selectedMarkers() {
    if (this.mapFilterValue === 0) {
      return this.markers;
    }
    if (this.mapFilterValue === 1) {
      return this.markers.filter((item) => item.type !== "DirectSale");
    }
    if (this.mapFilterValue === 2) {
      return this.markers.filter((item) => item.type === "DirectSale");
    }
  }
}





















































































































































import { Component, PropSync, Vue, Watch } from "vue-property-decorator";

const VueSlickCarousel = require("vue-slick-carousel");

import CardOffer from "../cards/CardOffer.vue";
import SplitContent from "../content/SplitContent.vue";
import CardContent from "../cards/CardContent.vue";
import Map from "../map/Map.vue";

@Component({
  components: { CardOffer, SplitContent, CardContent, VueSlickCarousel, Map },
})
export default class HomePage extends Vue {
  @PropSync("model")
  privateModel!: any;

  selected: string = "all";

  offersSliderOptions = {
    slidesToShow: 4,
    arrows: true,
    infinite: false,
    dots: true,
    autoplay: false,

    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  get offers() {
    return [
      ...this.privateModel.Auctions.auctions,
      ...this.privateModel.DirectSales.auctions,
    ];
  }

  get topOffers() {
    let array = [
      ...this.privateModel.Auctions.auctions.slice(0, 3),
      ...this.privateModel.DirectSales.auctions.slice(0, 3),
    ];

    if (this.selected === "auction") {
      array = [...this.privateModel.Auctions.auctions.slice(0, 3)];
    }

    if (this.selected === "sale") {
      array = [...this.privateModel.DirectSales.auctions.slice(0, 3)];
    }

    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * i);
      let k = array[i];
      array[i] = array[j];
      array[j] = k;
    }

    return array;
  }

  get filterIsVisible() {
    return (
      this.privateModel.Auctions &&
      this.privateModel.Auctions.auctions &&
      this.privateModel.Auctions.auctions.length > 0 &&
      this.privateModel.DirectSales &&
      this.privateModel.DirectSales.auctions &&
      this.privateModel.DirectSales.auctions.length > 0
    );
  }

  get saleLinkIsVisible() {
    return (
      (this.selected === "all" || this.selected === "sale") &&
      this.privateModel.DirectSales &&
      this.privateModel.DirectSales.auctions &&
      this.privateModel.DirectSales.auctions.length > 0
    );
  }

  get auctionLinkIsVisible() {
    return (
      (this.selected === "all" || this.selected === "auction") &&
      this.privateModel.Auctions &&
      this.privateModel.Auctions.auctions &&
      this.privateModel.Auctions.auctions.length > 0
    );
  }

  contentRows = [
    {
      id: "uzemni-plany",
      img: "img03.jpg",
      imgClass: "p-hp_img paint",
      title: "ÚZEMNÍ PLÁNY",
      text: "S naší analýzou nad územními plány nemusíte hledat. U nás vybíráte pozemek dle svých kritérií, efektivně a s náskokem před ostatními.",
      btn: {
        link: "https://www.cleverestate.cz/",
        label: "Územní plány",
        attributes: { target: "_blank" },
      },
    },
    {
      id: "mam-pudu-k-prodeji",
      img: "img01.jpg",
      imgClass: "p-hp_img paint",
      title: "MÁM PŮDU K&nbsp;PRODEJI",
      text: "Využijte internetové aukce. Bez rizika, rychle, s překvapivým (zajímavým) výnosem. Za účast v aukci nezaplatíte ani korunu, pokud půdu úspěšně nevydražíte.",
      btn: {
        link: "/pozemky/prodat",
        label: "Chci prodat",
      },
    },
    {
      id: "potrebuji-dalsi-pudu",
      img: "img02.jpg",
      imgClass: "p-hp_img paint",
      title: "POTŘEBUJI DALŠÍ PŮDU",
      text: "Pomůžeme Vám rozšířit Vaše hospodářství o vhodnou půdu právě ve Vašem okolí.",
      btn: {
        link: "/pozemky/prodej",
        label: "Chci koupit",
      },
    },
  ];

  contentCards = [
    {
      img: "medal.svg",
      title: "MÁME DLOUHOLETÉ ZKUŠENOSTI A&nbsp;ZÁZEMÍ",
      text: "Tvoříme trh - zprostředkujeme prodej/nákup - vše na&nbsp;klíč. Úspěšně jsme realizovali již 1&nbsp;300 obchodů a&nbsp;téměř 2&nbsp;800 ha půdy.",
    },
    {
      img: "thumb.svg",
      title: "VSTŘÍCNÉ JEDNÁNÍ - POMŮŽEME VÁM A&nbsp;PORADÍME",
      text: "Zajistíme hladký průběh obchodu s&nbsp;půdou, včetně potřebných smluv. Vaše finance jsou tak v&nbsp;bezpečí. Vyřešíme i&nbsp;předkupní právo.",
    },
    {
      img: "notes.svg",
      title: "OCHRÁNÍME VÁS PŘED RIZIKY A&nbsp;SPEKULANTY",
      text: "Prodej půdy může pro nezkušeného člověka znamenat rizika. Případná chyba Vás může stát spoustu peněz, obtíží i&nbsp;času.",
    },
    {
      img: "handshake.svg",
      title: "NEPLATÍTE PROVIZI, POKUD OBCHOD NENÍ REALIZOVÁN",
      text: "Snažíme se&nbsp;tvořit výhodnější podmínky pro zemědělce/hos. subjekty. Poradíme v&nbsp; oblasti správy či&nbsp;můžeme správu vyřešit za&nbsp;Vás.",
    },
  ];

  aboutUs = {
    title: "O NÁS",
    text: "Jsme tým odborníků s dlouholetými zkušenostmi. Na počátku jsme byli součástí společnosti CleverMaps avšak od března 2018 se rozvíjíme samostatně jako Moře půdy, spol. s r.o.",
    btn: {
      label: "KONTAKTUJTE NÁS",
      link: "/kontakt",
    },
  };

  @Watch("topOffers")
  changeOffers() {
    this.offersSliderOptions.slidesToShow = 1;
    this.$nextTick(() => {
      this.offersSliderOptions.slidesToShow = 4;
    });
  }
}

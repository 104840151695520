

























import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class Switch3 extends Vue {
  @Prop({ default: 0 }) value!: number;
  @Prop({ default: null }) id!: string;
  @Prop({ default: null }) name!: string;
  @Prop({ default: null }) label1!: string;
  @Prop({ default: null }) label2!: string;

  switchValue() {
    if (this.value === 0) {
      this.$emit("update:value", 1);
    } else if (this.value === 1) {
      this.$emit("update:value", 2);
    } else if (this.value === 2) {
      this.$emit("update:value", 0);
    }
  }

  changeValue(val: number) {
    this.$emit("update:value", val);
  }
}
















import { Component, PropSync, Vue } from "vue-property-decorator";

@Component
export default class CardContent extends Vue {
  @PropSync("img")
  privateImg!: string;

  @PropSync("title")
  privateTitle!: string;

  @PropSync("text")
  privateText!: string;
}


























import { Component, PropSync, Vue } from "vue-property-decorator";

@Component
export default class SplitContent extends Vue {
  @PropSync("content", {
    default: {
      id: null,
      img: null,
      imgClass: null,
      title: null,
      text: null,
      btn: {
        label: null,
        link: null,
      },
    },
  })
  privateContent!: {};
}
